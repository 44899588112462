/**
 Этот плагин загружает через сеть скрипты из зоны виджетов `СКРИПТЫ` админки /wp-admin
 Далее выполняет инлайновый JS или вставляет в head, если есть `src`
 Эти скрипты необходимы для отображения рекламных баннеров и брендирования
 На момент написания комментария это только ADFOX
 */
import type { Context } from '@nuxt/types';

import { UPDATE_IS_HEAD_SCRIPTS_LOADED } from '~/constants';
import { IWordpressApi } from '~/services/wordpress';
import { Scripts } from '~/libs/Scripts';
import separateScriptsAndContent from '~/libs/separateScriptsAndContent';
import { isAnyRecipePage, isHealthAboutProjectPage, isHealthExpertsPage, isHealthPath } from '~/router';

const runInitialScripts = async (store: Context['store'], $wordpressApi: IWordpressApi, route: Context['route']): Promise<void> => {
  let parsedHtmlWidgets: TWidget[] = [];

  // для страниц экспертов и о проекте не отображаем брендирование
  if (isHealthExpertsPage(route.path) || isHealthAboutProjectPage(route.path)) { return; }

  if (isAnyRecipePage(route.name)) {
    try {
      const { data, status } = await $wordpressApi.fetchRecipeScripts();
      if (status === 200) {
        parsedHtmlWidgets = data.filter(item => item.type === 'parsed-html');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  } else if (isHealthPath(route.path)) {
    try {
      parsedHtmlWidgets = store.state.widgets.healthScripts.filter((item: TWidget) => item.type === 'parsed-html');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  } else {
    try {
      const { data, status } = await $wordpressApi.fetchHeadScriptsFromServer(route.path);
      if (status === 200) {
        const { widgets } = data;
        parsedHtmlWidgets = widgets.filter((w: TWidget) => w.type === 'parsed-html');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  if (parsedHtmlWidgets.length) {
    const allInlineScripts: TScript[] = [];
    const allScripts: TScript[] = [];
    parsedHtmlWidgets.forEach((widget: TWidget) => {
      const { data } = widget;
      const { inlineScripts, scripts } = separateScriptsAndContent(data);
      allInlineScripts.push(...inlineScripts);
      allScripts.push(...scripts);
    });
    /**
     * когда загрузятся все src-скрипты и выполнятся инлайновые, делаем коммит
     * чтобы запустить отображение баннеров
     */
    new Scripts(
      allScripts,
      allInlineScripts,
      () => store.commit(UPDATE_IS_HEAD_SCRIPTS_LOADED, { isHeadScriptsLoaded: true }),
    ).addScripts();
  } else {
    store.commit(UPDATE_IS_HEAD_SCRIPTS_LOADED, { isHeadScriptsLoaded: true });
  }
};

export default ({ store, app: { $wordpressApi }, route }: Context): void => {
  window.onNuxtReady(() => runInitialScripts(store, $wordpressApi, route));
};
