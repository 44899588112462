import Vue from 'vue';

import {
  favoriteDelete,
  favoriteDeferred,
  favoriteAddToFolder,
  favoriteCreateFolderAndAdd,
  favoriteCreateFolderAndTransfer,
  favoriteTransferToFolder,
} from '~/utils/toggleFavoriteState';

export default Vue.extend({
  methods: {
    $_mixin_onFavoriteDelete (favorite: IFavoriteItem, article: IArticle, sendGTM: boolean = true, cb: Function = () => {}): void {
      favoriteDelete(favorite, article, this, sendGTM, cb);
    },

    $_mixin_onFavoriteCreateFolderAndAdd (nameFolder: string, article: IArticle, sendGTM: boolean = true, cb: Function = () => {}): void {
      favoriteCreateFolderAndAdd(nameFolder, article, this, sendGTM, cb);
    },

    $_mixin_onFavoriteCreateFolderAndTransfer (nameFolder: string, favorite: IFavoriteItem, article: IArticle, sendGTM: boolean = true, cb: Function = () => {}): void {
      favoriteCreateFolderAndTransfer(nameFolder, favorite, article, sendGTM, this, cb);
    },

    $_mixin_onFavoriteTransferToFolder (favorite: IFavoriteItem, folder: IFolderItem, article: IArticle, sendGTM: boolean = true, cb: Function = () => {}): void {
      favoriteTransferToFolder(favorite, folder, article, sendGTM, this, cb);
    },

    $_mixin_onFavoriteAddToFolder (folder: IFolderItem, article: IArticle, sendGTM: boolean = true): void {
      favoriteAddToFolder(folder, article, sendGTM, this);
    },

    $_mixin_onFavoriteDeferred (deferredAction: Function, ctx: any) {
      favoriteDeferred(deferredAction, ctx);
    },
  },
});
