import Vue from 'vue';
// @ts-ignore
import Talker from '@devhacker/talker-frontend/dist/lifehacker/talker.umd';
import { TALKER_API_URL, PUSHER_API_URL, AUTH_API_URL, ACCESS_TOKEN_COOKIE_NAME } from '~/constants/config';

export default async (Context: any): Promise<void> => {
  const { store } = Context;
  const talkerUrl = TALKER_API_URL;
  Vue.use(Talker.TalkerPlugin, {
    name: 'TestTalker',
    store,
    talkerUrl,
    cookieName: ACCESS_TOKEN_COOKIE_NAME,
    authUrl: AUTH_API_URL,
    pusherUrl: PUSHER_API_URL,
  });
  store.registerModule('talker', Talker.TalkerStore);
  await store.dispatch('talker/meta/SET_TALKER_URL_ACTION', talkerUrl);
};
