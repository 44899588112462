import type { Context } from '@nuxt/types';
import type { Route } from 'vue-router';
import { isHealthPath } from '../router/utils';
import { GA_ID_RECIPE, GA_ID } from '~/constants/config';
import { isAnyRecipePage, isPreviewPage } from '~/router';

export default function ({ $logger, route }: Context) {
  const log = $logger.withScope('🫐 google-analytics');
  const injected: string[] = [];

  initRouteSpecific(route);

  function initRouteSpecific (route: Route) {
    // превью и здоровье не считаем
    if (isPreviewPage(route.name) || isHealthPath(route.path)) { return; }

    if (!injected.includes(GA_ID)) {
      injectScript(GA_ID);
    }

    const recipeInjected = injected.includes(GA_ID_RECIPE);
    if (!recipeInjected && isAnyRecipePage(route.name)) {
      injectScript(GA_ID_RECIPE);
    }
  }

  function injectScript (id: string) {
    log('inject', id);
    const myScript = document.createElement('script');
    myScript.type = 'text/javascript';
    myScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}&l=${id}`;
    myScript.async = true;
    document.head.appendChild(myScript);

    // @ts-ignore
    const dataLayer = window[id] || [];
    dataLayer.push('js', new Date());
    dataLayer.push('config', id);
    // @ts-ignore
    window[id] = dataLayer;

    injected.push(id);
  }
}
