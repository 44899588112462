// @ts-ignore
import Pusher, { leavePrivateChannel } from 'lh-pusher-plugin/package/pusher.umd';
import type { Context } from '@nuxt/types';
import Vue from 'vue';
import { LOGOUT } from '~/constants';
import { isRequiresAuth } from '~/utils';
import { ACCESS_TOKEN_COOKIE_NAME, AUTH_API_URL, FULLSEARCH_API_URL, PUSHER_API_URL } from '~/constants/config';

export const config = {
  authUrl: AUTH_API_URL,
  pusherUrl: PUSHER_API_URL,
  fullSearchUrl: FULLSEARCH_API_URL,
  accessTokenCookieName: ACCESS_TOKEN_COOKIE_NAME,
};

export default (ctx: Context) => {
  const requiresAuth = isRequiresAuth(ctx.route);
  Vue.use(Pusher.PusherPlugin, { store: ctx.store, config });

  Pusher.AuthEventBus.$on('failed-refresh', () => {
    leavePrivateChannel(ctx.store, config);
    // eslint-disable-next-line
    console.log('failed-refresh pusher');
    ctx.store.dispatch(LOGOUT);
    ctx.store.commit('pusher/RESET_STORE');

    if (requiresAuth) {
      location.reload();
    }
  });

  Pusher.AuthEventBus.$on('refresh-success', () => {
    // eslint-disable-next-line
    console.log('refresh success pusher');
  });
};
